import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { regEx } from "../../assets/scripts/RegEx";
import { getURL } from "../../assets/scripts/Main";
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import { LinearProgress } from "@mui/material";

export default class Content extends Component {
  state = {
    items: [],
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    signUpIntegration: null,
    defaultSignUp: null,
    loading: false,
  };

  handleKeyPress = (event) => {
    this.setState({
      email: ReactDOM.findDOMNode(this.refs.email).value,
      username: ReactDOM.findDOMNode(this.refs.username).value,
      password: ReactDOM.findDOMNode(this.refs.password).value,
      confirmPassword: ReactDOM.findDOMNode(this.refs.confirmPassword).value,
    });
    if (event.key === "Enter") {
      this.signUp();
    }
  };

  signUp = () => {
    if (!regEx("email", this.state.email)) {
      this.errMsg("v1");
    } else if (this.state.username === "") {
      this.errMsg("v7");
    } else if (!regEx("username", this.state.username)) {
      this.errMsg("v2");
    } else if (this.state.password.length < 6 || this.state.password.length > 150) {
      this.errMsg("v3");
    } else if (!regEx("password", this.state.password)) {
      this.errMsg("v4");
    } else if (this.state.password !== this.state.confirmPassword) {
      this.errMsg("v5");
    } else if (!ReactDOM.findDOMNode(this.refs.termsOfUse).checked) {
      this.errMsg("v6");
    } else {
      this.setState({ loading: true });
      axios
        .post(getURL("API") + "/signUp", {
          email: this.state.email,
          username: this.state.username,
          password: this.state.password,
          confirmPassword: this.state.confirmPassword,
        })
        .then((response) => response.data)
        .then((data) => {
          this.setState({ loading: false });
          this.setState({ defaultSignUp: data });
        })
        .catch((error) => {
          this.setState({ loading: false });
          if (error.response.hasOwnProperty("data")) {
            this.errMsg(error.response.data.msg);
          } else {
            this.errMsg(error);
          }
        });
    }
  };

  signUpGoogle = () => {
    if (this.state.username === "") {
      this.errMsg("v7");
    } else if (!regEx("username", this.state.username)) {
      this.errMsg("v2");
    } else if (!ReactDOM.findDOMNode(this.refs.termsOfUse).checked) {
      this.errMsg("v6");
    } else {
      axios
        .post(getURL("API") + "/signUp/google", {
          signUpIntegration: this.state.signUpIntegration,
          username: this.state.username,
        })
        .then((response) => response.data)
        .then((data) => {
          this.setState({ items: data });
        })
        .catch((error) => {
          if (error.response.hasOwnProperty("data")) {
            this.errMsg(error.response.data.msg);
          } else {
            this.errMsg(error);
          }
        });
    }
  };

  responseGoogle = (response) => {
    this.setState({
      signUpIntegration: response.profileObj
        ? {
            integrationId: response.profileObj.googleId,
            email: response.profileObj.email,
            name: response.profileObj.name,
            imageUrl: response.profileObj.imageUrl,
          }
        : null,
      email: response.profileObj ? response.profileObj.email : "",
    });
  };

  errMsg = (msg) => {
    var emailErrMsg = "";
    var usernameErrMsg = "";
    var passwordErrMsg = "";
    var confirmPasswordErrMsg = "";
    var termsOfUseErrMsg = "";
    var googleErrMsg = "";

    if (msg === "v1" || msg === "5") {
      emailErrMsg = "Formato do email incorreto";
    } else if (msg === "v2" || msg === "1") {
      usernameErrMsg =
        "O nome de usuário deve ter entre 3 e 16 caracteres, e não pode ter caracteres especiais";
    } else if (msg === "v3") {
      passwordErrMsg = "Sua senha deve ter entre 6 e 150 caracteres";
    } else if (msg === "v4") {
      passwordErrMsg = "Sua senha deve conter números, letras e caracteres especiais";
    } else if (msg === "v5") {
      confirmPasswordErrMsg = "As senhas não conferem";
    } else if (msg === "v6") {
      termsOfUseErrMsg = "Para criar o cadastro, você deve aceitar os termos de uso";
    } else if (msg === "v7") {
      usernameErrMsg = "Insira um nome de usuário";
    } else if (msg === "6") {
      usernameErrMsg = "Já existe um usuário " + this.state.username;
    } else if (msg === "7") {
      emailErrMsg = "Já existe uma conta com o email " + this.state.email;
    } else if (msg === "8") {
      googleErrMsg =
        "Este email já possui cadastro. <a href='./login' style=font-size:14px;>Fazer Login</a>";
    }

    ReactDOM.findDOMNode(this.refs.emailErrMsg).innerHTML = emailErrMsg;
    ReactDOM.findDOMNode(this.refs.usernameErrMsg).innerHTML = usernameErrMsg;
    ReactDOM.findDOMNode(this.refs.passwordErrMsg).innerHTML = passwordErrMsg;
    ReactDOM.findDOMNode(this.refs.confirmPasswordErrMsg).innerHTML = confirmPasswordErrMsg;
    ReactDOM.findDOMNode(this.refs.termsOfUseErrMsg).innerHTML = termsOfUseErrMsg;
    ReactDOM.findDOMNode(this.refs.googleErrMsg).innerHTML = googleErrMsg;
  };

  render() {
    let success =
      this.state.items.success && this.state.signUpIntegration ? this.state.items.success : null;
    let msg = this.state.items.msg && this.state.signUpIntegration ? this.state.items.msg : null;

    if (success) {
      let dataToken = JSON.parse(atob(msg.split(".")[1]));
      let page = localStorage.getItem("page");

      localStorage.setItem("token", msg);

      if (typeof page !== "undefined" && page !== null) {
        localStorage.removeItem("page");
        window.location.href = page;
      } else {
        window.location.href = "/perfil/" + dataToken.username;
      }
    }

    return (
      <>
        <form method="post" className="defaultContainer signUp">
          <div id="background">
            <div className="align">
              <div className="bgAlign"></div>
              <div className="formAlign">
                <div className="formSignUp defaultBox">
                  {this.state.defaultSignUp ? (
                    <p className={this.state.emailSent ? "" : "hidden"}>
                      Enviamos um email de confirmação para <strong>{this.state.email}</strong>.
                      <br />
                      <br />
                      Não se esqueça de verificar a caixa de spam
                    </p>
                  ) : (
                    ""
                  )}
                  {this.state.signUpIntegration ? (
                    <p>
                      Para prosseguir com o <strong>Google</strong>, crie abaixo um nome de usuário
                    </p>
                  ) : (
                    ""
                  )}
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    onKeyUp={this.handleKeyPress}
                    disabled={this.state.signUpIntegration ? true : false}
                    value={
                      this.state.signUpIntegration ? this.state.signUpIntegration.email : this.value
                    }
                    ref="email"
                    style={this.state.defaultSignUp ? { display: "none" } : {}}
                  />
                  <span
                    className="errMsg"
                    id="emailErrMsg"
                    ref="emailErrMsg"
                    style={this.state.defaultSignUp ? { display: "none" } : {}}
                  ></span>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Nome de Usuário"
                    onKeyUp={this.handleKeyPress}
                    ref="username"
                    style={this.state.defaultSignUp ? { display: "none" } : {}}
                  />
                  <span
                    className="errMsg"
                    id="usernameErrMsg"
                    ref="usernameErrMsg"
                    style={this.state.defaultSignUp ? { display: "none" } : {}}
                  ></span>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Senha"
                    onKeyUp={this.handleKeyPress}
                    style={
                      this.state.signUpIntegration || this.state.defaultSignUp
                        ? { display: "none" }
                        : {}
                    }
                    ref="password"
                  />
                  <span
                    className="errMsg"
                    id="passwordErrMsg"
                    ref="passwordErrMsg"
                    style={this.state.defaultSignUp ? { display: "none" } : {}}
                  ></span>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirmar Senha"
                    onKeyUp={this.handleKeyPress}
                    style={
                      this.state.signUpIntegration || this.state.defaultSignUp
                        ? { display: "none" }
                        : {}
                    }
                    ref="confirmPassword"
                  />
                  <span
                    className="errMsg"
                    id="confirmPasswordErrMsg"
                    ref="confirmPasswordErrMsg"
                  ></span>
                  <div style={this.state.defaultSignUp ? { display: "none" } : {}}>
                    <input
                      label="asdg"
                      type="checkbox"
                      name="termsOfUse"
                      id="termsOfUse"
                      placeholder="Confirmar Senha"
                      ref="termsOfUse"
                    />

                    <label for="termsOfUse">
                      Li e aceito os{" "}
                      <Link to="/termos-de-uso" target="_blank">
                        Termos de Uso
                      </Link>
                      .
                    </label>
                    <div>
                      <span className="errMsg" id="termsOfUseErrMsg" ref="termsOfUseErrMsg"></span>
                    </div>
                  </div>
                  <input
                    type="button"
                    value="Cadastrar"
                    onClick={this.state.signUpIntegration ? this.signUpGoogle : this.signUp}
                    style={this.state.defaultSignUp ? { display: "none" } : {}}
                  />
                  {this.state.loading && (
                    <>
                      <br />
                      <br />
                      <LinearProgress />
                    </>
                  )}
                  {/*  <div
                    id="alternativeSignUpSeparator"
                    style={
                      this.state.signUpIntegration || this.state.defaultSignUp
                        ? { display: "none" }
                        : {}
                    }
                  >
                    Ou
                  </div>
                  <span
                    style={
                      this.state.signUpIntegration || this.state.defaultSignUp
                        ? { display: "none" }
                        : {}
                    }
                  >
                    <div id="googleButton">
                      <GoogleLogin
                        clientId="114613198168-lmpbhmus6e4fplaecck81o6umfvjec5o.apps.googleusercontent.com"
                        buttonText="Acessar com Google"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                    </div>
                  </span> */}
                  <span className="errMsg" id="googleErrMsg" ref="googleErrMsg"></span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
