import React, { Component } from "react";
import { getURL, validateImage, getBase64, extractMetadata } from "../../assets/scripts/Main";
import axios from "axios";
import { Alert, Grid, LinearProgress, Divider, Button, IconButton } from "@mui/material";
//import Tesseract from "tesseract.js";

import TextField from "../../components/mui/TextField";
import Typography from "../../components/mui/Typography";
import SearchIcon from "@mui/icons-material/Search";
import ImageIcon from "@mui/icons-material/Image";
import CancelIcon from "@mui/icons-material/Cancel";
import { getByICAOCodeOrIATACode } from "../../services/airport";
import { getByRegistrationCode } from "../../services/aircraft";

export default class Center extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseUrlPhoto: "",
      airport: "",
      aircraft: "",
      searchAirport: false,
      searchAircraft: false,
      registration_code: "",
      incorrectInfos: "",
      incorrectInfosDescriptions: "",
      error: false,
      alert: false,
      alertMsg: "",
      loadingAirport: false,
      loadingAircraft: false,
      loadingPhoto: false,
      camMake: "",
      camModel: "",
      camLensModel: "",
      camExposureTime: "",
      camFNumber: "",
      camISO: "",
      photoDate: "",
      ICAOAirport: "",
      IATAAirport: "",
      airportId: null,
      aircraftId: null,
    };
  }

  handleFileInputChange = async (e) => {
    this.setState({ loadingPhoto: true });
    let file = e.target.files[0];
    let baseURL = await getBase64(file);
    let validate = await validateImage(baseURL, 1);

    //this.setState({ tesseractImage: URL.createObjectURL(file) });

    if (!validate.success) {
      this.setState({
        error: true,
        alert: false,
        alertMsg: validate.msg,
        baseUrlPhoto: "",
      });
    } else {
      let metadata = extractMetadata(baseURL);
      this.setState({
        error: false,
        alert: validate.msg ? true : false,
        alertMsg: validate.msg,
        baseUrlPhoto: baseURL,
        camMake: metadata.Make,
        camModel: metadata.Model,
        camLensModel: metadata.LensModel,
        camExposureTime: metadata.ExposureTime,
        camFNumber: metadata.FNumber,
        camISO: metadata.ISO,
        photoDate: metadata.Date,
      });
    }
    this.setState({ loadingPhoto: false });
  };

  handleSearchAirport = () => {
    if (this.state.ICAOAirport || this.state.IATAAirport) {
      this.setState({ loadingAirport: true, searchAirport: true });

      getByICAOCodeOrIATACode({
        icao_code: this.state.ICAOAirport,
        iata_code: this.state.IATAAirport,
      })
        .then((data) => {
          let airport = `${data.data.name} - ${data.data.location} (${data.data.icao_code} - ${data.data.iata_code})`;
          this.setState({
            airport: airport,
            airportId: data.data.id,
            loadingAirport: false,
          });
        })
        .catch(() => {
          this.setState({
            loadingAirport: false,
            airport: "",
          });
        });
    }
  };

  handleSearchAircraft = () => {
    if (this.state.registration_code) {
      this.setState({
        loadingAircraft: true,
        searchAircraft: true,
      });

      getByRegistrationCode(this.state.registration_code)
        .then((data) => {
          let aircraft = `${data.data.manufacturer}  ${data.data.model} (${data.data.registration_code})`;

          this.setState({
            aircraft: aircraft,
            aircraftId: data.data.aircraft_id,
            loadingAircraft: false,
            registration_code: data.data.registration_code,
          });
        })
        .catch(() => {
          this.setState({
            loadingAircraft: false,
            aircraft: "",
          });
        });
    }
  };

  handleCleanAirport = () => {
    this.setState({
      airport: "",
      airportId: 0,
      searchAirport: false,
      ICAOAirport: "",
      IATAAirport: "",
    });
  };

  handleCleanAircraft = () => {
    this.setState({
      aircraft: "",
      aircraftId: 0,
      searchAircraft: false,
      registration_code: "",
    });
  };

  sendPhoto = () => {
    this.setState({ loadingPhoto: true });
    const formData = new FormData();
    formData.append("registration_code", this.state.registration_code);
    formData.append("photo", this.state.photo);
    axios
      .request({
        method: "POST",
        url: getURL("API") + "/photo/sendPhoto/",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          imageBase64: this.state.baseUrlPhoto,
          icao_code: this.state.ICAOAirport,
          iata_code: this.state.IATAAirport,
          registration_code: this.state.registration_code,
          incorret_infos: this.state.incorrectInfosDescriptions,
          aircraft_id: this.state.aircraftId,
          airport_id: this.state.airportId,
          camMake: this.state.camMake,
          camModel: this.state.camModel,
          camLensModel: this.state.camLensModel,
          camExposureTime: this.state.camExposureTime,
          camFNumber: this.state.camFNumber,
          camISO: this.state.camISO,
          photoDate: this.state.photoDate,
        },
      })
      .then((data) => {
        console.log(data);
        document.location =
          getURL("root").split("/enviar-foto")[0] +
          "/foto/" +
          data.data.photoId +
          "/" +
          (data.data.registration_code || "");
      })
      .catch(() => {
        this.setState({
          loadingPhoto: false,
          error: true,
          alertMsg: "Ocorreu um erro no envio da foto. Tente novamente mais tarde.",
          baseUrlPhoto: "",
        });
      });
  };

  /*   handleOCR = () => {
    console.log("ue");
    if (!this.state.tesseractImage) {
      console.log("---");
      return;
    }

    Tesseract.recognize(
      this.state.tesseractImage, // Caminho ou blob da imagem
      "eng", // Idioma (ex.: 'eng' para inglês, 'por' para português)
      {
        logger: (info) => {
          if (info.status === "recognizing text") {
            console.log("leno");
          }
        },
        tessedit_char_whitelist: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-",
      }
    )
      .then(({ data: { text } }) => {
        console.log(text);
      })
      .catch((error) => {
        console.log("Erro no OCR:", error);
      });
  }; */

  render() {
    return (
      <>
        <div id="sendPhoto">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={5}>
              <div
                className="thumb"
                style={{
                  backgroundImage: "url(" + this.state.baseUrlPhoto + ")",
                }}
              >
                preview
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <Button
                component="label"
                variant="contained"
                id="uploadButton"
                startIcon={<ImageIcon />}
              >
                Selecionar Imagem
                <input
                  id="files"
                  style={{ display: "none" }}
                  type="file"
                  onChange={this.handleFileInputChange}
                ></input>
              </Button>
            </Grid>
            {(this.state.error || this.state.alert) && (
              <Grid item xs={12}>
                <Alert severity={this.state.error ? "error" : "info"} variant="filled">
                  {this.state.alertMsg}
                </Alert>
              </Grid>
            )}
            {this.state.loadingPhoto && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
          </Grid>
          <br />
          <Typography variant="h6" gutterBottom>
            Informações sobre a foto
          </Typography>

          <span
            style={{
              visibility: this.state.loadingAirport ? "visible" : "hidden",
            }}
          >
            <LinearProgress />
            <br />
          </span>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Marca da Câmera"
                value={this.state.camMake}
                onChange={(e) => this.setState({ camMake: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Modelo"
                value={this.state.camModel}
                onChange={(e) => this.setState({ camModel: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Lente"
                value={this.state.camLensModel}
                onChange={(e) => this.setState({ camLensModel: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Exposição"
                value={this.state.camExposureTime}
                onChange={(e) => this.setState({ camExposureTime: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="F"
                value={this.state.camFNumber}
                onChange={(e) => this.setState({ camFNumber: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="ISO"
                value={this.state.camISO}
                onChange={(e) => this.setState({ camISO: e.target.value })}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ color: "#6d7683" }}>Aeroporto</Divider>
            </Grid>

            <Grid item xs={5} md={4} lg={3}>
              <TextField
                label="Cód. ICAO do Aeroporto"
                inputProps={{ maxLength: 4 }}
                value={this.state.ICAOAirport}
                onChange={(e) => this.setState({ ICAOAirport: e.target.value.toUpperCase() })}
              />
            </Grid>

            <Grid item xs={2} md={1} lg={1} mt={1} sx={{ textAlign: "center" }}>
              <Typography variant="overline" gutterBottom>
                ou
              </Typography>
            </Grid>

            <Grid item xs={5} md={4} lg={3}>
              <TextField
                label="Cód. IATA do Aeroporto"
                inputProps={{ maxLength: 3 }}
                value={this.state.IATAAirport}
                onChange={(e) => this.setState({ IATAAirport: e.target.value.toUpperCase() })}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Button
                variant="contained"
                size="large"
                endIcon={<SearchIcon />}
                onClick={() => this.handleSearchAirport()}
              >
                Pesquisar
              </Button>
            </Grid>
            {this.state.airport && (
              <>
                <Grid item xs={1}>
                  <IconButton color="info" size="small" onClick={() => this.handleCleanAirport()}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={11} mt={1}>
                  <Typography variant="subtitle2" gutterBottom>
                    {this.state.airport}
                  </Typography>
                </Grid>
              </>
            )}
            {!this.state.airport && this.state.searchAirport && (
              <Grid item xs={12}>
                <Alert severity={"info"} variant="filled">
                  A aeroporto pesquisado ainda não está no site, mas você pode enviar a foto mesmo
                  assim.
                </Alert>
              </Grid>
            )}

            <Grid item xs={4} sx={{ display: "none" }}>
              <TextField label="data" value={this.state.photoDate} />
            </Grid>
          </Grid>
          <br />

          <Typography variant="h6" gutterBottom>
            Informações sobre a aeronave
          </Typography>

          <span
            style={{
              visibility: this.state.loadingAircraft ? "visible" : "hidden",
            }}
          >
            <LinearProgress />
            <br />
          </span>

          <Grid container spacing={2}>
            <Grid item xs={6} md={3} lg={3}>
              <TextField
                label="Matrícula"
                placeholder="PP-AER"
                value={this.state.registration_code || ""}
                onChange={(e) => this.setState({ registration_code: e.target.value.toUpperCase() })}
              />
            </Grid>
            <Grid item xs={6} md={9} lg={9}>
              <Button
                variant="contained"
                size="large"
                endIcon={<SearchIcon />}
                onClick={() => this.handleSearchAircraft()}
              >
                Pesquisar
              </Button>
            </Grid>
            {this.state.aircraft && (
              <>
                <Grid item xs={1}>
                  <IconButton color="info" size="small" onClick={() => this.handleCleanAircraft()}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={11} mt={1}>
                  <Typography variant="subtitle2" gutterBottom>
                    {this.state.aircraft}
                  </Typography>
                </Grid>
              </>
            )}
            {!this.state.aircraft && this.state.searchAircraft && (
              <Grid item xs={12}>
                <Alert severity={"info"} variant="filled">
                  A matrícula pesquisada ainda não está no site, mas você pode enviar a foto mesmo
                  assim.
                </Alert>
              </Grid>
            )}
          </Grid>
          <br />
          <div className="buttonAlign">
            <input
              type="button"
              id="sendPhoto"
              className={this.state.baseUrlPhoto ? "" : "disabled"}
              value="Enviar Foto 📤"
              onClick={this.state.baseUrlPhoto ? this.sendPhoto.bind(this) : null}
            />
          </div>
        </div>
      </>
    );
  }
}
