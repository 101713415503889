import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainerMUI";
import Right from "./Right";
import { CardContent, Box, Grid } from "@mui/material";
import Card from "../../components/mui/Card";

export default class Index extends Component {
  render() {
    return (
      <>
        <DefaultContainer>
          <Box mt={10}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6} lg={6} sx={{ display: { xs: "none", md: "unset" } }}>
                <div id="passwordRecoveryBg"></div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Card>
                  <CardContent>
                    <Right />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </DefaultContainer>
      </>
    );
  }
}
