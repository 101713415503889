import React from "react";
import { Card } from "@mui/material";
export default (props) => (
  <>
    <Card
      {...props}
      sx={{
        backgroundColor: "#141d29",
        color: "#dee5ed",
      }}
    />
  </>
);
