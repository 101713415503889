import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainerMUI";
import axios from "axios";
import Left from "./Left";
import CtrEditProfile from "./CtrEditProfile";
import CtrShowProfile from "./CtrShowProfile";
import {
  setPageTitle,
  getURL,
  getPathName,
  getDataToken,
  scrollToTop,
} from "../../assets/scripts/Main";
import { CardContent, Box, Grid } from "@mui/material";
import Card from "../../components/mui/Card";

export default class Index extends Component {
  state = {
    items: [],
    token: localStorage.getItem("token"),
    username: getPathName(2),
    dataToken: getDataToken(),
  };

  componentDidMount = () => {
    scrollToTop();
    this.getProfile();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (getPathName(2) !== prevState.username) {
      this.setState({ username: getPathName(2) });
      this.getProfile();
    }

    if (prevState.items !== this.state.items) {
      if (this.state.items.length === 0)
        window.location.href = "/pagina-nao-encontrada";
    }
  };

  getProfile = () => {
    if (this.state.username !== undefined)
      axios
        .get(getURL("API") + "/profile/" + this.state.username, {
          headers: { "x-access-token": this.state.token },
        })
        .then((response) => response.data)
        .then((data) => {
          this.setState({ items: data });
        });
  };

  Center = () => {
    switch (getPathName(1)) {
      case "editar-perfil":
        return <CtrEditProfile items={this.state.items} />;
      default:
        return <CtrShowProfile items={this.state.items} />;
    }
  };
  render() {
    setPageTitle("Perfil");
    return (
      <DefaultContainer>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Left items={this.state.items} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={9}>
              <Card>
                <CardContent>{this.Center()}</CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </DefaultContainer>
    );
  }
}
