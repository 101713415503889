import React, { Component } from "react";
import Typography from "../../components/mui/Typography";
import { setPageTitle, getImageThumb } from "../../assets/scripts/Main";
import { Box, Grid, CardMedia } from "@mui/material";
import WaitingLoad from "../../components/WaitingLoad";
import InnerCard from "../../components/mui/InnerCard";

export default class Center extends Component {
  componentDidMount = () => {
    this.setState({
      slideImageUrl: "",
    });
  };

  render() {
    let photo = this.props.photo;
    let user_images = this.props.user_images;
    let name = photo.name;
    let title =
      (photo.manufacturer ? photo.manufacturer + " " : "") +
      (photo.model ? photo.model + " " : "") +
      (photo.registration_code || "");

    setPageTitle((title ? title + " | " : "") + "Aeronaves.Info");

    return (
      <>
        {name ? (
          <Typography variant="h5" gutterBottom>
            Outras fotos de {name}:
          </Typography>
        ) : (
          <>
            <WaitingLoad width={"100%"} height={"15px"} />
            <br />
            <br />
            <WaitingLoad width={"50%"} height={"130px"} />
            <WaitingLoad width={"50%"} height={"130px"} />
            <br />
            <br />
            <WaitingLoad width={"50%"} height={"130px"} />
            <WaitingLoad width={"50%"} height={"130px"} />
          </>
        )}

        <Grid container spacing={2}>
          {user_images &&
            user_images.map((photo) => {
              return (
                <Grid item xs={6} md={4} lg={4}>
                  <Box>
                    <InnerCard>
                      <a href={"/foto/" + photo.id + "/" + (photo.title || "")}>
                        <CardMedia
                          component="img"
                          height="120"
                          image={getImageThumb(photo.url)}
                          alt={photo.title}
                        />
                        <Box p={0.5}>
                          <Typography variant="body2" sx={{ display: "inline-block" }}>
                            {photo.title ? (
                              <>
                                <strong>{photo.title + " "}</strong>
                                {photo.subtitle}
                              </>
                            ) : (
                              "✈️"
                            )}
                          </Typography>
                        </Box>
                      </a>
                    </InnerCard>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </>
    );
  }
}
