import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CenterContainer from "../../components/CenterContainer";
import { getUrlParameter, getURL, getImageThumb } from "../../assets/scripts/Main";
import { Grid, CardMedia, Box, Avatar, Divider } from "@mui/material";
import Card from "../../components/mui/Card";
import Chip from "../../components/mui/Chip";
import Typography from "../../components/mui/Typography";

var lastListedId = null;
var lastCalledId = null;
var lastPostPosition = null;
export default class Center extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      aircrafts: [],
      searchWord: getUrlParameter("key"),
    };
  }

  componentDidMount = () => {
    if (getUrlParameter("key").length > 2) {
      this.getPhotos(0);
      this.getAircrafts();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.state.searchWord !== getUrlParameter("key") && getUrlParameter("key").length > 2) {
      lastListedId = null;
      lastCalledId = null;
      lastPostPosition = null;

      this.setState({
        photos: [],
        aircrafts: [],
        searchWord: getUrlParameter("key"),
      });

      window.onscroll = () => {};
      this.getPhotos(lastListedId, getUrlParameter("key"));
      this.getAircrafts(getUrlParameter("key"));
    }
  };

  getAircrafts = (searchWord) => {
    axios
      .get(getURL("API") + "/aircraft/search/" + (searchWord || this.state.searchWord))
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          aircrafts: data,
        });
      });
  };

  getPhotos = (lastListedId, searchWord) => {
    axios
      .get(
        getURL("API") + "/feed/search/" + (searchWord || this.state.searchWord) + "/" + lastListedId
      )
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          photos: this.state.photos.concat(data),
        });
      });
  };

  paginationScroll = (arg) => {
    var post = document.getElementById(`pb${arg}`);
    if (post) {
      lastPostPosition = post.getBoundingClientRect().top;
    }
    if (arg && lastPostPosition < window.innerHeight + 200 && lastCalledId !== arg) {
      this.getPhotos(arg);
      lastCalledId = arg || lastCalledId;
      lastListedId = null;
      lastPostPosition = null;
    }
  };

  render() {
    window.onscroll = () => {
      this.paginationScroll(lastListedId);
    };

    return (
      <Grid container spacing={2}>
        {this.state.aircrafts.length > 0 && (
          <Grid item xs={12}>
            <Divider>
              <Chip label="Aeronaves" size="small" />
            </Divider>
          </Grid>
        )}
        {this.state.aircrafts.length > 0 &&
          this.state.aircrafts.map((item) => {
            console.log(item);
            return (
              <>
                <Grid item xs={6} md={4} lg={4}>
                  <div id={`pb${item.id}`} key={`pb${item.id}`}>
                    <Link
                      to={"/aeronave/" + item.aircraft_id + "/" + (item.registration_code || "")}
                    >
                      <Box>
                        <Card>
                          <Box p={1}>
                            <Typography variant="body2" sx={{ display: "inline-block" }}>
                              {item.registration_code ? (
                                <>
                                  <strong>{item.registration_code}</strong>
                                  {item.manufacturer ? " " + item.manufacturer : ""}
                                  {item.model ? " " + item.model : ""}
                                </>
                              ) : (
                                "✈️"
                              )}
                            </Typography>
                          </Box>
                          <CardMedia
                            component="img"
                            height="120"
                            image={
                              item.url_thumb
                                ? item.url_thumb
                                : "../../assets/img/default_images/aircraft.png"
                            }
                            alt={
                              item.registration_code
                                ? item.registration_code +
                                  (item.manufacturer ? " " + item.manufacturer : "") +
                                  (item.model ? " " + item.model : "")
                                : item.name
                            }
                          />
                        </Card>
                      </Box>
                    </Link>
                  </div>
                </Grid>
              </>
            );
          })}
        {this.state.photos.length > 0 && (
          <Grid item xs={12}>
            <Divider>
              <Chip label="Fotos" size="small" />
            </Divider>
          </Grid>
        )}

        {this.state.photos.length > 0 &&
          this.state.photos.map((item) => {
            lastListedId = item.id;
            return (
              <Grid item xs={6} md={4} lg={4}>
                <div id={`pb${item.id}`} key={`pb${item.id}`}>
                  <Link to={"/foto/" + item.id + "/" + (item.registration_code || "")}>
                    <Box>
                      <Card>
                        <Box p={1}>
                          <Typography variant="body2" sx={{ display: "inline-block" }}>
                            {item.registration_code ? (
                              <>
                                <strong>{item.registration_code}</strong>
                                {item.manufacturer ? " " + item.manufacturer : ""}
                                {item.model ? " " + item.model : ""}
                              </>
                            ) : (
                              "✈️"
                            )}
                          </Typography>
                        </Box>
                        <CardMedia
                          component="img"
                          height="120"
                          image={
                            item.photo_url
                              ? getImageThumb(item.photo_url)
                              : "../../assets/img/default_images/aircraft.png"
                          }
                          alt={
                            item.registration_code
                              ? item.registration_code +
                                (item.manufacturer ? " " + item.manufacturer : "") +
                                (item.model ? " " + item.model : "")
                              : item.name
                          }
                        />
                        <Box p={0.5}>
                          <Grid container>
                            <Avatar
                              alt="Remy Sharp"
                              src={item.user_profile_picture}
                              sx={{ width: "30px", height: "30px" }}
                            />

                            <Typography variant="caption" style={{ margin: "6px 10px" }}>
                              {item.name}
                            </Typography>
                          </Grid>
                        </Box>
                      </Card>
                    </Box>
                  </Link>
                </div>
              </Grid>
            );
          })}

        {!this.state.aircrafts.length && !this.state.photos.length && (
          <>
            {getUrlParameter("key").length < 3 ? (
              <div className="noResults">Digite pelo menos três caracteres</div>
            ) : (
              <div className="noResults">Nenhum resultado</div>
            )}
          </>
        )}
      </Grid>
    );
  }
}
