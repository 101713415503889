import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import DefaultContainer from "../../components/DefaultContainerMUI";
import Card from "../../components/mui/Card";
import Left from "./Left";
import Center from "./Center";
import { setPageTitle } from "../../assets/scripts/Main";

export default class Index extends Component {
  render() {
    setPageTitle("Aeronaves.Info");

    return (
      <>
        <DefaultContainer>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "unset" } }}>
                <Left />
                <div className="about">
                  <Link to="/termos-de-uso" target="_Blank">
                    {" "}
                    Termos de uso
                  </Link>{" "}
                  -
                  <Link to="/sobre" target="_Blank">
                    {" "}
                    Sobre nós
                  </Link>
                  <br />
                  <br />
                  <div
                    style={{
                      backgroundImage: `url(./assets/img/icons/br-mg.png)`,
                    }}
                  >
                    Aeronaves.Info &copy; 2024&nbsp;&nbsp;|
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Center />
              </Grid>
            </Grid>
          </Box>
        </DefaultContainer>
      </>
    );
  }
}
