import React, { Component } from "react";
import { Link } from "react-router-dom";
import { setPageTitle, getPathName, getImageThumb } from "../../assets/scripts/Main";
import WaitingLoad from "../../components/WaitingLoad";
import Typography from "../../components/mui/Typography";
import Chip from "../../components/mui/Chip";
import { getPhotosByUsername } from "../../services/photo";
import { Box, Grid, CardMedia, IconButton } from "@mui/material";
import InnerCard from "../../components/mui/InnerCard";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

var lastListedId = 0;

export default class Center extends Component {
  state = {
    photos: [],
    showLoadMore: false,
  };

  componentDidMount = () => {
    lastListedId = 0;
    this.listUserPhotos();
  };

  listUserPhotos = () => {
    getPhotosByUsername(getPathName(2), lastListedId)
      .then((data) => {
        this.setState({ showLoadMore: data.data.length === 20 });
        data?.data?.map((item) => {
          lastListedId = item.id;
          this.setState({
            photos: this.state.photos.concat({
              url: item.photo_url,
              registration_code: item.registration_code,
              id: item.id,
            }),
          });
        });
      })
      .catch((e) => {
        this.setState({ showLoadMore: false });
      });
  };

  render() {
    var user = this.props.items;

    setPageTitle(user.name + " | Aeronaves.Info");

    return (
      <>
        {user.name ? (
          <Typography variant="h5" gutterBottom>
            Fotos de {user.name}:
          </Typography>
        ) : (
          <>
            <WaitingLoad width={"100%"} height={"15px"} />
            <br />
            <br />
            <WaitingLoad width={"50%"} height={"130px"} />
            <WaitingLoad width={"50%"} height={"130px"} />
          </>
        )}

        {this.state.photos.length === 0 ? (
          <>
            <Typography variant="caption">Nenhuma foto publicada por {user.name}.</Typography>
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              {this.state.photos &&
                this.state.photos.map((photo) => {
                  return (
                    <Grid item xs={6} md={4} lg={4}>
                      <Box>
                        <InnerCard>
                          <Link to={"/foto/" + photo.id + "/" + (photo.registration_code || "")}>
                            <CardMedia
                              component="img"
                              height="120"
                              image={getImageThumb(photo.url)}
                              alt={photo.registration_code || ""}
                            />
                          </Link>
                          <Box p={0.5}>
                            --
                            {/*                  <Grid container>
                              <IconButton color="primary" aria-label="Editar informações">
                                <EditIcon />
                              </IconButton>
                              <IconButton color="error" aria-label="delete">
                                <DeleteIcon />
                              </IconButton>
                              <IconButton color="secondary" aria-label="delete">
                                <VisibilityOffIcon />
                              </IconButton>
                            </Grid> */}
                          </Box>
                        </InnerCard>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}

        {this.state.showLoadMore && (
          <Box sx={{ textAlign: "center" }} mt={2}>
            <Chip
              label="Carregar Mais"
              size="big"
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => this.listUserPhotos()}
            />
          </Box>
        )}
      </>
    );
  }
}
