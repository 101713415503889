import React, { Component } from "react";
import { getDataToken, getPathName } from "../../assets/scripts/Main";
import { Link } from "react-router-dom";
import WaitingLoad from "../../components/WaitingLoad";
import { Avatar, Grid } from "@mui/material";
import Chip from "../../components/mui/Chip";

export default class Left extends Component {
  render() {
    const logedUser = getDataToken();
    console.log(logedUser);
    const propsUser = this.props.items;
    const editPage = getPathName(1) === "editar-perfil";
    const username = editPage ? logedUser.username : propsUser.username;
    const name = editPage ? logedUser.name : propsUser.name;
    const profilePicture = editPage ? logedUser.profilePicture : propsUser.profilePicture;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="leftProfileAvatar">
            <Avatar alt={name} src={profilePicture} sx={{ width: "70px", height: "70px" }} />
          </div>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <div id="name">{name || <WaitingLoad width={"100px"} height={"20px"} />}</div>
        </Grid>

        {editPage && (
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Link to={"/perfil/" + username}>
              <Chip
                label="Ver Perfil 👤"
                size="big"
                style={{ textAlign: "center", cursor: "pointer" }}
              />
            </Link>
          </Grid>
        )}

        {logedUser.username === getPathName(2) && (
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <a href={"/editar-perfil"}>
              <Chip
                label="Editar Perfil ✏️"
                size="big"
                style={{ textAlign: "center", cursor: "pointer" }}
              />
            </a>
          </Grid>
        )}
      </Grid>
    );
  }
}
