import { get } from "./_base";

const getPhotosByAircraftId = (id) => {
  return get("/photosByAircraft/" + id);
};

const getPhotosByUserId = (id) => {
  return get("/photosByUserId/" + id);
};

const getPhotosByUsername = (username, lastListedId) => {
  return get("/photosByUsername/" + username + "/" + lastListedId);
};

export { getPhotosByAircraftId, getPhotosByUserId, getPhotosByUsername };
