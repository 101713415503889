import React from "react";
import HeaderContainer from "./HeaderContainer";
import CssBaseline from "@mui/material/CssBaseline";
import { Container } from "@mui/material";

export default (props) => (
  <>
    <HeaderContainer />
    <React.Fragment>
      <CssBaseline />
      <Container sx={{ paddingTop: "80px" }} {...props} />
    </React.Fragment>
  </>
);
