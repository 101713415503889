import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getDataToken,
  getURL,
  getUrlParameter,
  validateImage,
  getBase64,
} from "../../assets/scripts/Main";
import { Avatar, Grid, LinearProgress, Box, Alert } from "@mui/material";
import axios from "axios";
import TextField from "../../components/mui/TextField";
import Button from "../../components/mui/Button";
import SaveIcon from "@mui/icons-material/Save";
import ImageIcon from "@mui/icons-material/Image";

export default class Center extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseUrlPhoto: "",
      userId: null,
      name: null,
      username: null,
      profilePicture: null,
      profileDescription: null,
      urlTwitter: null,
      urlInstagram: null,
      loading: false,
      enableSaveButton: false,
      error: false,
      success: getUrlParameter("success") === "t",
      alertMsg: "",
    };
  }

  componentDidMount = () => {
    this.getProfile();
  };

  handleFileInputChange = async (e) => {
    this.setState({
      loading: true,
      error: false,
      alertMsg: "",
    });
    let { file } = this.state;
    file = e.target.files[0];
    let baseURL = await getBase64(file);
    let validate = await validateImage(baseURL, 2);
    if (!validate.success) {
      this.setState({
        error: true,
        alertMsg: validate.msg,
        baseUrlPhoto: "",
      });
    } else {
      this.setState({
        baseUrlPhoto: baseURL,
        enableSaveButton: true,
      });
    }
    this.setState({ loading: false });
  };

  getProfile = () => {
    let username = getDataToken().username;

    axios.get(getURL("API") + "/profile/" + username).then((data) => {
      data = data.data;

      this.setState({
        userId: data.id,
        name: data.name,
        username: username,
        profilePicture: data.profilePicture,
        profileDescription: data.profileDescription,
        urlTwitter: data.urlTwitter,
        urlInstagram: data.urlInstagram,
      });
    });
  };

  editProfile = () => {
    this.setState({
      error: false,
      loading: true,
      alertMsg: "",
    });
    axios
      .request({
        method: "POST",
        url: getURL("API") + "/profile/edit/",
        headers: { "x-access-token": localStorage.getItem("token") },
        data: {
          userId: this.state.userId,
          name: this.state.name,
          username: this.state.username,
          profileDescription: this.state.profileDescription,
          urlTwitter: this.state.urlTwitter,
          urlInstagram: this.state.urlInstagram,
          baseUrlPhoto: this.state.baseUrlPhoto,
        },
      })
      .then((response) => response.data)
      .then((data) => {
        localStorage.setItem("token", data.msg);
        window.location.href = "/editar-perfil?success=t";
      })
      .catch((error) => {
        this.setState({
          error: true,
          loading: false,
        });
        if (error.response.data.msg === "1") {
          this.setState({
            alertMsg: "Nome de usuário já está em uso.",
          });
        } else {
          this.setState({
            alertMsg: "Erro ao atualizar perfil. Tente novamente mais tarde.",
          });
        }
      });
  };

  render() {
    return (
      <>
        <div id="editProfile">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={4}
                label="Bio"
                placeholder="Descreva aqui sua bio..."
                value={this.state.profileDescription || ""}
                onChange={(event) => {
                  this.setState({
                    enableSaveButton: true,
                    profileDescription: event.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
          <Box mt={4} mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Box mt={1}>
                  <label>Foto de perfil</label>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Avatar
                  alt="Remy Sharp"
                  src={this.state.baseUrlPhoto || this.state.profilePicture}
                />
              </Grid>
              <Grid item xs={9}>
                <Button
                  component="label"
                  variant="contained"
                  id="uploadButton"
                  startIcon={<ImageIcon />}
                >
                  Selecionar Imagem
                  <input
                    id="files"
                    style={{ display: "none" }}
                    type="file"
                    onChange={this.handleFileInputChange}
                  ></input>
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Nome"
                value={this.state.name || ""}
                onChange={(event) => {
                  this.setState({
                    enableSaveButton: true,
                    name: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Username"
                value={this.state.username || ""}
                onChange={(event) => {
                  this.setState({
                    enableSaveButton: true,
                    username: event.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Instagram"
                value={this.state.urlInstagram || ""}
                placeholder="https://www.instagram.com/usuario"
                onChange={(event) => {
                  this.setState({
                    enableSaveButton: true,
                    urlInstagram: event.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="𝕏 (Twitter)"
                value={this.state.urlTwitter || ""}
                placeholder="https://x.com/usuario"
                onChange={(event) => {
                  this.setState({
                    enableSaveButton: true,
                    urlTwitter: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  visibility: this.state.loading ? "visible" : "hidden",
                }}
              />
            </Grid>
            {this.state.error && (
              <Grid item xs={12}>
                <Alert severity={"error"} variant="filled">
                  {this.state.alertMsg}
                </Alert>
              </Grid>
            )}
            {this.state.success && (
              <Grid item xs={12}>
                <Alert
                  severity={"success"}
                  variant="filled"
                  action={
                    <Link to="/editar-perfil" style={{ color: "#fff" }}>
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => this.setState({ success: false })}
                      >
                        Ok
                      </Button>
                    </Link>
                  }
                >
                  Dados atualizados!
                </Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                size="large"
                endIcon={<SaveIcon />}
                onClick={() => this.editProfile()}
                disabled={!this.state.enableSaveButton}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}
