import React, { Component } from "react";
import { Link } from "react-router-dom";
import WaitingLoad from "../../components/WaitingLoad";
import { Avatar, Grid } from "@mui/material";
import Chip from "../../components/mui/Chip";

export default class Left extends Component {
  render() {
    let photo = this.props.photo;
    let name = photo.name;
    let username = photo.username;
    let user_profile_picture = photo.user_profile_picture;
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="leftProfileAvatar">
              <Avatar
                alt={name}
                src={user_profile_picture}
                sx={{ width: "70px", height: "70px" }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <div id="name">{name || <WaitingLoad width={"100px"} height={"20px"} />}</div>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Link to={"/perfil/" + username}>
              <Chip
                label="Ver Perfil 👤"
                size="big"
                style={{ textAlign: "center", cursor: "pointer" }}
              />
            </Link>
          </Grid>
        </Grid>
      </>
    );
  }
}
