import React, { Component } from "react";
import Chip from "../../components/mui/Chip";
import { Link } from "react-router-dom";

export default class Top extends Component {
  state = {
    modalOpen: false,
  };

  render() {
    let photo = this.props.photo;
    let aircraft_id = photo.aircraft_id;
    let registration_code = photo.registration_code;
    let manufacturer = photo.manufacturer;
    let model = photo.model;
    let photo_url = photo.photo_url;
    let company = photo.company;
    let name = photo.name;
    let username = photo.username;
    const title =
      (manufacturer ? manufacturer + " " : "") +
      (model ? model + " " : "") +
      (registration_code ? "(" + registration_code + ")" : "") +
      (company ? " - " + company : "");
    const altTitle =
      title + (name ? ". by: " + name : "") + (username ? " (" + username + ")" : "");

    return (
      <>
        <div id="aircraftPhotoContainer">
          <img
            class="photo"
            src={photo_url || "/assets/img/default_images/aircraft_photo.png"}
            alt={altTitle}
            onClick={() => this.setState({ modalOpen: true })}
          />
          {this.state.modalOpen && (
            <div class="modalPhotoPage" open={this.state.modalOpen} sx={{ zIndex: "9999" }}>
              <Chip
                label={"Fechar"}
                size="big"
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => this.setState({ modalOpen: false })}
              />

              <br />
              <img
                src={photo_url || "/assets/img/default_images/aircraft_photo.png"}
                alt={altTitle}
                onClick={() => this.setState({ modalOpen: false })}
              />
            </div>
          )}

          <img src="/assets/img/scroll.gif" className="scroll" />
          <div className="description">
            {registration_code ? (
              <>
                {title}{" "}
                <Link to={"/aeronave/" + aircraft_id + "/" + registration_code}>
                  <Chip
                    label={"Mais sobre " + registration_code + " ✈️"}
                    size="big"
                    style={{ textAlign: "center", cursor: "pointer" }}
                  />
                </Link>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}
