import React, { Component } from "react";
import { getDataToken } from "../../assets/scripts/Main";
import { Link } from "react-router-dom";
import WaitingLoad from "../../components/WaitingLoad";
import { Avatar, Grid, Box } from "@mui/material";
import Chip from "../../components/mui/Chip";

export default class Left extends Component {
  render() {
    const logedUser = getDataToken();
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="leftProfileAvatar">
            <Avatar
              alt={logedUser.name}
              src={logedUser.profilePicture}
              sx={{ width: "70px", height: "70px" }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <div id="name">{logedUser.name || <WaitingLoad width={"100px"} height={"20px"} />}</div>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Link to={"/perfil/" + logedUser.username}>
            <Chip
              label="Ver Perfil 👤"
              size="big"
              style={{ textAlign: "center", cursor: "pointer" }}
            />
          </Link>
        </Grid>
      </Grid>
    );
  }
}
