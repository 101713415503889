import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { getURL, getUrlParameter } from "../../assets/scripts/Main";

export default class Content extends Component {
  state = {
    items: [],
  };

  componentDidMount = () => {
    axios
      .post(getURL("API") + "/signUp/validate", {
        verificationCode: getUrlParameter("code"),
      })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ items: data });
      })
      .catch((error) => {
        this.errMsg(error.response.data.msg);
      });
  };

  errMsg = (msg) => {
    var errMsg = "";
    if (msg === "9") {
      errMsg =
        "😕 Não conseguimos validar seu email, clique no botão CRIAR ACESSO e refaça seu cadastro.<br><br>Lembrando que o email de validação tem validade de 3h";
    } else {
      errMsg = "😕 Houve um erro ao tentar validar seu email. Tente novamente mais tarde.";
    }
    ReactDOM.findDOMNode(this.refs.errMsg).innerHTML = errMsg;
  };

  render() {
    return (
      <form method="post" className="defaultContainer signUp">
        <div id="background">
          <div className="align">
            <div className="bgAlign"></div>
            <div className="formAlign">
              <div className="formSignUp defaultBox">
                {this.state.items.success ? (
                  <span className="successMsg" id="successMsg" ref="successMsg">
                    <strong>Email validado!</strong> <br />
                    <br />
                    Clique no botão <a href="./login">Login</a> para prosseguir com seu acesso.
                  </span>
                ) : (
                  <span className="errMsg" id="errMsg" ref="errMsg"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
