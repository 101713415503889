import React from "react";
import { Chip } from "@mui/material";
export default (props) => (
  <>
    <Chip
      {...props}
      sx={{
        backgroundColor: "#233245",
        color: "#dee5ed",
      }}
    />
  </>
);
