import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link, Redirect } from "react-router-dom";
import { getDataToken, getPathName, getUrlParameter } from "../assets/scripts/Main";
import HeaderMenu from "./HeaderMenu";
import CssBaseline from "@mui/material/CssBaseline";
import { Container, Box, Grid, IconButton } from "@mui/material";
import Button from "./mui/Button";
import Typography from "./mui/Typography";
import MenuIcon from "@mui/icons-material/Menu";

export default class Index extends Component {
  state = {
    search: "",
    redirect: false,
    dataToken: getDataToken(),
  };

  handleKeyPress = (event) => {
    this.setState({
      search: ReactDOM.findDOMNode(this.refs.SearchInput).value,
    });

    if (event.key === "Enter") {
      this.redirect(this.state.search);
    }
  };
  handleSearchClick = () => {
    this.setState({
      search: ReactDOM.findDOMNode(this.refs.SearchInput).value,
    });
    this.redirect(this.state.search);
  };

  signMenuClick = () => {
    if (this.state.styleSignMenu.display === "none") {
      this.setState({
        styleSignMenu: { ...this.state.styleSignMenu, display: "block" },
      });
    } else {
      this.setState({
        styleSignMenu: { ...this.state.styleSignMenu, display: "none" },
      });
    }
  };
  componentDidMount = () => {
    ReactDOM.findDOMNode(this.refs.SearchInput).value =
      getPathName(1) === "busca" ? getUrlParameter("key") : "";
  };

  redirect = (ref) => {
    this.setState({
      redirect: true,
    });
  };

  render() {
    return (
      <>
        {this.state.redirect && (
          <>
            {this.setState({
              redirect: false,
            })}
            <Redirect to={{ pathname: "/busca/?key=" + this.state.search }} />
          </>
        )}

        <header className="headerContainer">
          <div id="headerItems">
            {getPathName(1) ? (
              <Link to="/" id="headerLogo">
                <img src={`/assets/img/logo.png`} />
              </Link>
            ) : (
              <a href="/" id="headerLogo">
                <img src={`/assets/img/logo.png`} />
              </a>
            )}
            <div className="search">
              <input type="button" id="searchButton" value="" onClick={this.handleSearchClick} />

              <input
                type="text"
                id="search"
                name="search"
                placeholder="Buscar"
                onKeyUp={this.handleKeyPress}
                ref="SearchInput"
                defaultValue={this.state.search}
              />
            </div>

            <a href="/enviar-foto">
              <div class="sendPhotoComponent">
                <Typography variant="body2">
                  Spotter, envie <br />
                  sua foto 📷✈️
                </Typography>
              </div>
            </a>
            {!this.state.dataToken.username && (
              <div class="signInSignUpButtons">
                <Link to="/login">
                  <Button size="short">Login</Button>
                </Link>
                <Link to="/cadastro">
                  <Button variant="contained" size="short" color="success">
                    Cadastro
                  </Button>
                </Link>
              </div>
            )}
            <HeaderMenu
              username={this.state.dataToken.username}
              profilePicture={this.state.dataToken.profilePicture}
              name={this.state.dataToken.name}
            />
          </div>
        </header>
      </>
    );
  }
}
