import axios from "axios";
import ExifReader from "exifreader";

export function instanceAxios() {
  return axios.create({
    baseURL: getURL("API"),
    timeout: 1000 * 45,
    headers: { "x-access-token": localStorage.getItem("token") },
  });
}

export function setPageTitle(title) {
  document.title = title;
}
export function dynamicMetatags(ref) {
  for (var i in ref) {
    var metaTag = document.querySelector("meta[name='" + ref[i].name + "']");
    metaTag.content = ref[i].content;
  }
}

export function getURL(ref) {
  switch (ref) {
    case "API":
      if (
        window.location.href.indexOf("https://aeronaves.info") >= 0 ||
        window.location.href.indexOf("www.aeronaves.info") >= 0
      ) {
        return "https://aeronaves.info:7001";
      } else if (
        window.location.href.indexOf("https://homolog.aeronaves.info") >= 0 ||
        window.location.href.indexOf("www.homolog.aeronaves.info") >= 0
      ) {
        return "https://homolog.aeronaves.info:6001";
      } else {
        return "http://localhost:7000";
      }
    case "root":
      return window.location.href;

    default:
      return null;
  }
}
export function verifyJwt(route) {
  let pathCurrent = getPathName(1);
  let token = getDataToken();
  let now = Math.floor(Date.now() / 1000);
  let exp = token.exp;

  try {
    if (route.indexOf(pathCurrent) >= 0 && (token === null || exp < now)) {
      localStorage.setItem("page", getPathName(1));
      return false;
    } else {
      return true;
    }
  } catch (err) {}
  return false;
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}
export function getPathName(position) {
  if (position) return window.location.pathname.split("/")[position];

  return window.location.pathname;
}

export function getUrlParameter(parameter) {
  return document.location.toString().split("?" + parameter + "=")[1];
}

export function getDataToken() {
  let token = localStorage.getItem("token");

  let objToken = {
    id: "",
    username: "",
    name: "",
    iat: "",
    exp: "",
    profilePicture: "",
  };

  if (token) {
    var dataToken = JSON.parse(atob(token.split(".")[1]));
    let now = Math.floor(Date.now() / 1000);
    let exp = dataToken.exp;
    if (exp < now) {
      localStorage.setItem("token", "");
      return objToken;
    }

    objToken.id = dataToken.id;
    objToken.username = dataToken.username;
    objToken.name = dataToken.name;
    objToken.profilePicture = dataToken.profilePicture;
    objToken.iat = dataToken.iat;
    objToken.exp = dataToken.exp;

    return objToken;
  } else {
    return objToken;
  }
}

export async function base64ToFile(stringBase64, fileName, mimeType) {
  return fetch(stringBase64)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}

export async function getBase64(file) {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
}

const base64ToArrayBuffer = (base64Image) => {
  // Remove o cabeçalho se existir (ex: data:image/jpeg;base64,)
  const base64Data = base64Image.replace(/^data:image\/\w+;base64,/, "");

  // Converte a string Base64 para um buffer
  const binaryString = Buffer.from(base64Data, "base64").toString("binary");
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export function extractMetadata(base64Image) {
  let tags = {
    Make: "",
    Model: "",
    LensModel: "",
    ExposureTime: "",
    FNumber: "",
    ISO: "",
    Date: "",
  };
  const arrayBuffer = base64ToArrayBuffer(base64Image);
  const getTags = ExifReader.load(arrayBuffer);

  tags.Make = getTags.Make?.description || "";
  tags.Model = getTags.Model?.description || "";
  tags.LensModel = getTags.LensModel?.description || "";
  tags.ExposureTime = getTags.ExposureTime?.description || "";
  tags.FNumber = getTags.FNumber?.description || "";
  tags.ISO = getTags.ISOSpeedRatings?.description || "";
  tags.Date = getTags.DateTime?.description
    ? getTags.DateTime?.description.substring(0, 10).replace(/:/g, "-")
    : "";

  return tags;
}

export function getImageThumb(url) {
  let imageName = "";
  url.split("/").map((name) => {
    imageName = name;
  });
  return url.replace(imageName, "") + "thumb_" + imageName;
}

export async function validateImage(base64Image, type) {
  return new Promise((resolve, reject) => {
    const AIRCRAFT = 1;
    const PROFILE_PICTURE = 2;
    const base64Pattern = /^data:image\/(png|jpeg|jpg);base64,/;

    //valida o tipo
    let mimiType = base64Image.split(";")[0].replace(/data:/g, "");
    if (mimiType !== "image/png" && mimiType !== "image/jpeg") {
      resolve({ success: false, msg: "Envie apenas imagem PNG e JPG." });
    }
    //
    //estudar troca para:
    if (!base64Pattern.test(base64Image)) {
      resolve({ success: false, msg: "Envie apenas imagem PNG e JPG." });
    }

    //valida o tamanho
    const base64Data = base64Image.replace(base64Pattern, "");
    const base64Length = base64Data.length;
    const sizeInBytes =
      (base64Length * 3) / 4 - (base64Image.endsWith("==") ? 2 : base64Image.endsWith("=") ? 1 : 0);
    const sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 15 && type === AIRCRAFT) {
      resolve({
        success: false,
        msg: "A imagem não pode ser maior que 15MB",
      });
    }
    if (sizeInMB > 5 && type === PROFILE_PICTURE) {
      resolve({
        success: false,
        msg: "A imagem não pode ser maior que 5MB",
      });
    }

    //valida as dimensões
    let img = new Image();
    img.src = base64Image;

    img.onload = function () {
      var width = this.width;
      var height = this.height;

      if (type === AIRCRAFT) {
        if (height > width) {
          resolve({
            success: false,
            msg: "Envie uma foto no formato paisagem (largura mair que altura).",
          });
        }
        if (width < 500) {
          resolve({
            success: false,
            msg: "Envie uma foto com largura mínima de 500px.",
          });
        }
        if (width > 1800) {
          resolve({
            success: true,
            msg: "Sua foto será redimensionada para 1800px de largura.",
          });
        }
      } else if (type === PROFILE_PICTURE) {
        if (width > 1000) {
          resolve({
            success: false,
            msg: "Envie uma foto com largura máxima de 1000px.",
          });
        }
      }
      resolve({ success: true, msg: "" });
    };
  });
}
