import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { getURL, getPathName } from "../../assets/scripts/Main";
import WaitingLoad from "../../components/WaitingLoad";
import Typography from "../../components/mui/Typography";
import { CardContent, Box, Grid } from "@mui/material";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Card from "../../components/mui/Card";

export default class Left extends Component {
  state = {
    items: [],
  };
  componentDidMount() {
    axios
      .get(getURL("API") + "/aircraft_ranking", {
        params: { postId: getPathName(3) },
      })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ items: data });
      });
  }
  render() {
    return (
      <Card>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            Mais visitados
          </Typography>
          {this.state.items.length > 0 ? (
            <>
              {this.state.items.map((item) => (
                <Link
                  to={"aeronave/" + item.aircraft_id + "/" + item.registration_code}
                  key={`mons${item.aircraft_id}`}
                >
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <div
                          className="aircraftRankingIMG"
                          style={
                            item.url_thumb
                              ? {
                                  backgroundImage: `url(${item.url_thumb})`,
                                }
                              : {
                                  backgroundImage: `url(./assets/img/default_images/aircraft.png)`,
                                }
                          }
                        ></div>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="button">{item.registration_code}</Typography>
                        <br />
                        <Typography variant="caption">
                          {" "}
                          {item.manufacturer + (item.model ? " " + item.model : "")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Link>
              ))}
            </>
          ) : (
            <>
              <WaitingLoad width="100%" height="100px" />
              <WaitingLoad width="100%" height="100px" />
              <WaitingLoad width="100%" height="100px" />
            </>
          )}
        </CardContent>
      </Card>
    );
  }
}
