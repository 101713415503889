import React, { Component } from "react";
import { Link } from "react-router-dom";
import { setPageTitle, getPathName, getImageThumb } from "../../assets/scripts/Main";
import Typography from "../../components/mui/Typography";
import InnerCard from "../../components/mui/InnerCard";
import {
  CardContent,
  Grid,
  Box,
  Accordion,
  CardMedia,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getPhotosByAircraftId } from "../../services/photo";
export default class Center extends Component {
  state = {
    images: [],
  };

  componentDidMount = () => {
    getPhotosByAircraftId(getPathName(2))
      .then((data) => {
        data.data.map((item) => {
          this.setState({
            images: this.state.images.concat({
              url: item.photo_url,
              title: item.airport_icao,
              subtitle: "by " + item.name,
              id: item.id,
            }),
          });
        });
      })
      .catch((e) => {});
  };

  render() {
    let aircraft = this.props.items;
    let updated_at = aircraft.updated_at;
    let title =
      (aircraft.manufacturer ? aircraft.manufacturer + " " : "") +
      (aircraft.model ? aircraft.model + " " : "") +
      (aircraft.manufacturer ? "(" + aircraft.registration_code + ")" : aircraft.registration_code);

    setPageTitle(title + " | Aeronaves.Info");

    return (
      <>
        {/*                 <span className="publicationDate">
          {updated_at ? (
            <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
              {"Última atualização: " + updated_at.substring(0, 10).split("-").reverse().join("/")}
            </Typography>
          ) : (
            <WaitingLoad width={"100px"} height={"15px"} />
          )}
        </span> */}

        {title && title !== "undefined" && (
          <Box sx={{ display: { xs: "unset", md: "none" } }}>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>

            <br />
          </Box>
        )}

        <Grid container spacing={2}>
          {aircraft.manufacturer && (
            <Grid item xs={12} md={4} lg={4}>
              <InnerCard>
                <CardContent>
                  <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                    Fabricante
                  </Typography>
                  <br />
                  <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                    {aircraft.manufacturer}
                  </Typography>
                </CardContent>
              </InnerCard>
            </Grid>
          )}
          {aircraft.model && (
            <Grid item xs={12} md={4} lg={4}>
              <InnerCard>
                <CardContent>
                  <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                    Modelo
                  </Typography>
                  <br />
                  <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                    {aircraft.model}
                  </Typography>
                </CardContent>
              </InnerCard>
            </Grid>
          )}

          {aircraft.company && (
            <Grid item xs={12} md={4} lg={4}>
              <InnerCard>
                <CardContent>
                  <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                    Operador
                  </Typography>
                  <br />
                  <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                    {aircraft.company}
                  </Typography>
                </CardContent>
              </InnerCard>
            </Grid>
          )}
          <Grid item xs={12}>
            <Accordion sx={{ background: "#233245" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Mais Informações
              </AccordionSummary>
              <AccordionDetails>
                {(aircraft.icao_type ||
                  aircraft.year ||
                  aircraft.serial_number ||
                  aircraft.aircraft_class ||
                  aircraft.maximum_takeoff_weight ||
                  aircraft.maximum_passenger_number ||
                  aircraft.authorized_flight_type ||
                  aircraft.minimum_flight_crew ||
                  aircraft.number_of_seats ||
                  aircraft.register_category ||
                  aircraft.airworthiness_condition) && (
                  <Grid item xs={12}>
                    <Box>
                      {/*               <Card>
                        <CardContent> */}
                      <table class="info" cellpadding="0" cellspacing="0">
                        {aircraft.icao_type && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Tipo ICAO
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.icao_type}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.year && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Ano de Fabricação
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.year}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.serial_number && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Número de Série
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.serial_number}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.aircraft_class && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Classe
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.aircraft_class}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.maximum_takeoff_weight && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Peso Máx. Decolagem
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.maximum_takeoff_weight}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.maximum_passenger_number && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Número de Passageiros
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.maximum_passenger_number}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.authorized_flight_type && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Tipo de Voo
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.authorized_flight_type}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.minimum_flight_crew && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Tripulação Mínima
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.minimum_flight_crew}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.number_of_seats && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Número de Assentos
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.number_of_seats}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.register_category && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Categoria de Registro
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.register_category}
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {aircraft.airworthiness_condition && (
                          <tr>
                            <th>
                              <Typography variant="caption" gutterBottom sx={{ display: "block" }}>
                                Situação de Aeronavegabilidade
                              </Typography>
                            </th>
                            <td>
                              <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                {aircraft.airworthiness_condition}
                              </Typography>
                            </td>
                          </tr>
                        )}
                      </table>
                      {/*         </CardContent>
                      </Card> */}
                    </Box>
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <Typography variant="h6" gutterBottom>
            Fotos
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {this.state.images &&
            this.state.images.map((photo) => {
              return (
                <Grid item xs={6} md={4} lg={4}>
                  <Box>
                    <InnerCard>
                      <Link to={"/foto/" + photo.id + "/" + aircraft.registration_code}>
                        <CardMedia
                          component="img"
                          height="120"
                          image={getImageThumb(photo.url)}
                          alt={photo.title}
                        />
                        <Box p={0.5} pl={1}>
                          <Typography variant="caption" sx={{ display: "inline-block" }}>
                            {photo.subtitle}
                          </Typography>
                        </Box>
                      </Link>
                    </InnerCard>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </>
    );
  }
}
