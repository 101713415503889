import React, { Component } from "react";
import WaitingLoad from "../../components/WaitingLoad";
import { CardContent, Grid, CardMedia, Box } from "@mui/material";
import Card from "../../components/mui/Card";
import Typography from "../../components/mui/Typography";

export default class Left extends Component {
  render() {
    let aircraft = this.props.items;
    let registration_code = aircraft.registration_code;
    let url_thumb = aircraft.url_thumb;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Card>
                <CardMedia
                  component="img"
                  height="120"
                  image={url_thumb || "../../assets/img/default_images/aircraft.png"}
                  alt={registration_code}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <div id="code">
                        {registration_code || <WaitingLoad width={"100px"} height={"20px"} />}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}
