import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainerMUI";
import axios from "axios";
import Left from "./Left";
import Center from "./Center";
import { getURL, getPathName, scrollToTop } from "../../assets/scripts/Main";
import { CardContent, Box, Grid } from "@mui/material";
import Card from "../../components/mui/Card";

export default class Index extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    scrollToTop();
    axios
      .get(getURL("API") + "/aircraft/" + getPathName(2))
      .then((response) => response.data)
      .then((data) => {
        this.setState({ items: data });
      });
  }

  render() {
    let url = "";
    this.state.items.length > 0 &&
      this.state.items.map((item) => {
        url = item.registration_code;
      });

    return (
      <>
        <DefaultContainer>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3} sx={{ display: { xs: "none", md: "unset" } }}>
                <Left items={this.state.items} />
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Card>
                  <CardContent>
                    <Center items={this.state.items} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </DefaultContainer>
      </>
    );
  }
}
