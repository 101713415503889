import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { getURL, getImageThumb } from "../../assets/scripts/Main";
import { Grid, CardMedia, Box, Avatar } from "@mui/material";
import Card from "../../components/mui/Card";
import Typography from "../../components/mui/Typography";

var lastListedId = null;
var lastCalledId = null;
var lastPostPosition = null;
export default class Center extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount = () => {
    this.reqService(0);
  };
  componentWillUnmount() {
    lastListedId = null;
    lastCalledId = null;
    lastPostPosition = null;
    //removendo a função paginationScroll do evento
    window.onscroll = () => {};
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.username !== prevProps.username) {
      this.setState({
        items: [],
      });
      this.reqService(0);
    }
  };

  reqService = (arg) => {
    let link = typeof this.props.username !== "undefined" ? "user/" + this.props.username : "";
    if (link === "") {
      link = arg === 0 ? "" : arg;
    }
    axios
      .get(getURL("API") + "/feed/" + link)
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          items: this.state.items.concat(data),
        });
      });
  };
  paginationScroll = (arg) => {
    var post = document.getElementById(`pb${arg}`);
    if (post) {
      lastPostPosition = post.getBoundingClientRect().top;
    }

    if (
      lastPostPosition < window.innerHeight + 200 &&
      lastCalledId !== arg &&
      arg &&
      window.location.pathname === "/"
    ) {
      this.reqService(arg);
      lastCalledId = arg || lastCalledId;
      lastListedId = null;
      lastPostPosition = null;
    }
  };

  render() {
    window.onscroll = () => {
      this.paginationScroll(lastListedId);
    };
    return (
      <>
        <Grid container spacing={2}>
          {/* FIXO --------------- */}
          {/*           <Grid item xs={12}>
            <Box>
              <Card>
                <a href="./enviar-foto">
                  <CardMedia
                    component="img"
                    height="270"
                    image={"https://aeronaves.info/assets/img/call-banner.jpg"}
                    alt={"Spotter, envie suas fotos para o aeronaves.info"}
                  />
                </a>
                <Box p={0.5}>
                  <Grid container>
                    <Avatar
                      alt="Remy Sharp"
                      src={"https://aeronaves.info/assets/img/folder.jpg"}
                      sx={{ width: "30px", height: "30px" }}
                    />

                    <Typography variant="caption" style={{ margin: "6px 10px" }}>
                      {" "}
                      aeronaves.info
                    </Typography>
                  </Grid>
                </Box>
              </Card>
            </Box>
          </Grid> */}
          {/* FIXO --------------- */}

          {this.state.items.map((item) => {
            lastListedId = item.id;

            return (
              <Grid item xs={6} md={4} lg={4}>
                <div id={`pb${item.id}`} key={`pb${item.id}`}>
                  <Link to={"/foto/" + item.id + "/" + (item.registration_code || "")}>
                    <Box>
                      <Card>
                        <Box p={1}>
                          <Typography variant="body2" sx={{ display: "inline-block" }}>
                            {item.registration_code ? (
                              <>
                                <strong>{item.registration_code}</strong>
                                {item.manufacturer ? " " + item.manufacturer : ""}
                                {item.model ? " " + item.model : ""}
                              </>
                            ) : (
                              "✈️"
                            )}
                          </Typography>
                        </Box>
                        <CardMedia
                          component="img"
                          height="120"
                          image={
                            item.photo_url
                              ? getImageThumb(item.photo_url)
                              : "../../assets/img/default_images/aircraft.png"
                          }
                          alt={
                            item.registration_code
                              ? item.registration_code +
                                (item.manufacturer ? " " + item.manufacturer : "") +
                                (item.model ? " " + item.model : "")
                              : item.name
                          }
                        />
                        <Box p={0.5}>
                          <Grid container>
                            <Avatar
                              alt="Remy Sharp"
                              src={item.user_profile_picture}
                              sx={{ width: "30px", height: "30px" }}
                            />

                            <Typography variant="caption" style={{ margin: "6px 10px" }}>
                              {item.name}
                            </Typography>
                          </Grid>
                        </Box>
                      </Card>
                    </Box>
                  </Link>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }
}
