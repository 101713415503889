import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Menu, MenuItem, Divider, IconButton, Box, Grid, Avatar } from "@mui/material";
import Typography from "./mui/Typography";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import zIndex from "@mui/material/styles/zIndex";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#233245",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const exitProfile = () => {
    handleClose();
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  return (
    <div>
      {props.username && (
        <div className="logedUserComponent" onClick={handleClick}>
          <Grid container>
            <Typography variant="body2" className="name">
              {props.username}
            </Typography>
            <Avatar className="avatar" alt="Remy Sharp" src={props.profilePicture} />
          </Grid>
        </div>
      )}

      <IconButton
        aria-label="Menu"
        size="large"
        sx={{ mt: -0.7 }}
        className="mobileMenuIcon"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <StyledMenu
        style={{ zIndex: "9999" }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.username && (
          <Link to={"/perfil/" + props.username}>
            <MenuItem onClick={handleClose} disableRipple>
              <Avatar
                alt={props.name}
                src={props.profilePicture}
                sx={{ mr: 1, display: { xs: "inset", md: "none" } }}
              />
              <Typography variant="button">{props.name}</Typography>
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleClose} disableRipple>
              <PersonIcon />
              <Typography variant="body2">Perfil</Typography>
            </MenuItem>
          </Link>
        )}
        <a href={"/enviar-foto"}>
          <MenuItem onClick={handleClose} disableRipple>
            <PhotoCameraIcon />
            <Typography variant="body2"> Enviar Foto</Typography>
          </MenuItem>
        </a>

        {props.username ? (
          <MenuItem onClick={exitProfile} disableRipple>
            <LogoutIcon />
            <Typography variant="body2">Sair</Typography>
          </MenuItem>
        ) : (
          <>
            <Link to="/login">
              <MenuItem onClick={handleClose} disableRipple>
                <LoginIcon />
                <Typography variant="body2"> Login</Typography>
              </MenuItem>
            </Link>
            <Link to={"/cadastro"}>
              <MenuItem onClick={handleClose} disableRipple>
                <AccountCircleIcon />
                <Typography variant="body2"> Cadastro</Typography>
              </MenuItem>
            </Link>
          </>
        )}

        <Divider sx={{ my: 0.5 }} />
        <Link to="/termos-de-uso" target="_Blank">
          <MenuItem onClick={handleClose} disableRipple>
            <Typography variant="caption">Termos de uso</Typography>
          </MenuItem>
        </Link>

        <Link to="/sobre" target="_Blank">
          <MenuItem onClick={handleClose} disableRipple>
            <Typography variant="caption"> Sobre o Aeronaves.Info</Typography>
          </MenuItem>
        </Link>
      </StyledMenu>
    </div>
  );
}
