import React, { Component } from "react";
import DefaultContainer from "../../components/DefaultContainerMUI";
import axios from "axios";
import Left from "./Left";
import Top from "./Top";
import Center from "./Center";
import { getURL, getPathName, scrollToTop } from "../../assets/scripts/Main";
import { CardContent, Box, Grid } from "@mui/material";
import Card from "../../components/mui/Card";
import InnerCard from "../../components/mui/InnerCard";
import Typography from "../../components/mui/Typography";
import { getPhotosByUserId } from "../../services/photo";

export default class Index extends Component {
  state = {
    photo: {},
    user_images: [],
  };

  componentDidMount() {
    scrollToTop();
    axios
      .get(getURL("API") + "/photo/" + getPathName(2))
      .then((response) => response.data)
      .then((data) => {
        this.setState({ photo: data });
        this.listUserPhotos(data);
      });
  }

  listUserPhotos = (ac_data) => {
    getPhotosByUserId(ac_data.user_id)
      .then((data) => {
        data.data.map((item) => {
          this.setState({
            user_images: this.state.user_images.concat({
              url: item.photo_url,
              title: item.registration_code,
              subtitle: item.airport_icao,
              id: item.id,
            }),
          });
        });
      })
      .catch((e) => {});
  };

  render() {
    let url = "";

    this.state.photo.length > 0 &&
      this.state.photo.map((item) => {
        url = item.registration_code;
      });

    return (
      <>
        <Top photo={this.state.photo} />
        <DefaultContainer style={{ marginTop: "-80px" }}>
          <Box>
            <Grid container spacing={2}>
              {(this.state.photo.cam_make ||
                this.state.photo.cam_model ||
                this.state.photo.cam_lens ||
                this.state.photo.cam_exposure ||
                this.state.photo.cam_f ||
                this.state.photo.cam_iso ||
                this.state.photo.airport_name) && (
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        {this.state.photo.cam_make && (
                          <Grid item xs={3}>
                            <InnerCard sx={{ backgroundColor: "red" }}>
                              <CardContent>
                                <Typography
                                  variant="caption"
                                  gutterBottom
                                  sx={{ display: "block" }}
                                >
                                  Marca da Câmera
                                </Typography>
                                <br />
                                <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                  {this.state.photo.cam_make}
                                </Typography>
                              </CardContent>
                            </InnerCard>
                          </Grid>
                        )}

                        {this.state.photo.cam_model && (
                          <Grid item xs={3}>
                            <InnerCard sx={{ backgroundColor: "red" }}>
                              <CardContent>
                                <Typography
                                  variant="caption"
                                  gutterBottom
                                  sx={{ display: "block" }}
                                >
                                  Modelo
                                </Typography>
                                <br />
                                <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                  {this.state.photo.cam_model}
                                </Typography>
                              </CardContent>
                            </InnerCard>
                          </Grid>
                        )}

                        {this.state.photo.cam_lens && (
                          <Grid item xs={3}>
                            <InnerCard sx={{ backgroundColor: "red" }}>
                              <CardContent>
                                <Typography
                                  variant="caption"
                                  gutterBottom
                                  sx={{ display: "block" }}
                                >
                                  Lente
                                </Typography>
                                <br />
                                <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                  {this.state.photo.cam_lens}
                                </Typography>
                              </CardContent>
                            </InnerCard>
                          </Grid>
                        )}

                        {this.state.photo.cam_exposure && (
                          <Grid item xs={3}>
                            <InnerCard sx={{ backgroundColor: "red" }}>
                              <CardContent>
                                <Typography
                                  variant="caption"
                                  gutterBottom
                                  sx={{ display: "block" }}
                                >
                                  Exposição
                                </Typography>
                                <br />
                                <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                  {this.state.photo.cam_exposure}
                                </Typography>
                              </CardContent>
                            </InnerCard>
                          </Grid>
                        )}

                        {this.state.photo.cam_f && (
                          <Grid item xs={3}>
                            <InnerCard sx={{ backgroundColor: "red" }}>
                              <CardContent>
                                <Typography
                                  variant="caption"
                                  gutterBottom
                                  sx={{ display: "block" }}
                                >
                                  F
                                </Typography>
                                <br />
                                <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                  {this.state.photo.cam_f}
                                </Typography>
                              </CardContent>
                            </InnerCard>
                          </Grid>
                        )}

                        {this.state.photo.cam_iso && (
                          <Grid item xs={3}>
                            <InnerCard sx={{ backgroundColor: "red" }}>
                              <CardContent>
                                <Typography
                                  variant="caption"
                                  gutterBottom
                                  sx={{ display: "block" }}
                                >
                                  ISO
                                </Typography>
                                <br />
                                <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                  {this.state.photo.cam_iso}
                                </Typography>
                              </CardContent>
                            </InnerCard>
                          </Grid>
                        )}

                        {this.state.photo.airport_name && (
                          <Grid item xs={6}>
                            <InnerCard sx={{ backgroundColor: "red" }}>
                              <CardContent>
                                <Typography
                                  variant="caption"
                                  gutterBottom
                                  sx={{ display: "block" }}
                                >
                                  Aeroporto
                                </Typography>
                                <br />
                                <Typography variant="button" gutterBottom sx={{ display: "block" }}>
                                  {this.state.photo.airport_name}
                                </Typography>
                              </CardContent>
                            </InnerCard>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              <Grid item xs={3} sx={{ display: { xs: "none", md: "unset" } }}>
                <Card>
                  <CardContent>
                    <Left photo={this.state.photo} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Card>
                  <CardContent>
                    <Center photo={this.state.photo} user_images={this.state.user_images} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </DefaultContainer>
      </>
    );
  }
}
