import React from "react";
import { Switch, Route } from "react-router-dom";
import SignIn from "./views/SignIn/Index";
import SignUp from "./views/SignUp/Index";
import SignUpValidation from "./views/SignUpValidation/Index";
import Profile from "./views/Profile/index";
import SendPhoto from "./views/SendPhoto/index";
////import Settings from "./views/Settings/Index";
import Home from "./views/Home/Index";
import TermsOfUse from "./views/TermsOfUse/Index";
////import Forum from "./views/Forum/Index";
import Aircraft from "./views/Aircraft/Index";
import Photo from "./views/Photo/Index";
////import Article from "./views/Article/Index";
////import Postmaker from "./views/Postmaker/Index";
////import Tag from "./views/Tag/Index";
import Search from "./views/Search/Index";
import Error404 from "./views/Error404/Index";
import About from "./views/About/Index";
import PasswordRecovery from "./views/PasswordRecovery/Index";

const HomePage = () => <Home />;
const SignInPage = () => <SignIn />;
const SignUpPage = () => <SignUp />;
const SignUpValidationPage = () => <SignUpValidation />;
const ShowProfilePage = () => <Profile />;
//const ShowAriclesPage = () => <Profile />;
////const ShowForumsPage = () => <Profile />;
const EditProfilePage = () => <Profile />;
const SendPhotoPage = () => <SendPhoto />;
//const SettingsPage = () => <Settings />;
const TermsOfUsePage = () => <TermsOfUse />;
//const ForumPage = () => <Forum />;
const AircraftPage = () => <Aircraft />;
const PhotoPage = () => <Photo />;
//const ArticlePage = () => <Article />;
//const PostmakerPage = () => <Postmaker />;
//const TagPage = () => <Tag />;
const SearchPage = () => <Search />;
const Error404Page = () => <Error404 />;
const AboutPage = () => <About />;
const PasswordRecoveryPage = () => <PasswordRecovery />;

const Routes = () => (
  <Switch>
    <Route path="/" exact component={HomePage} />
    <Route path="/login" component={SignInPage} />
    <Route path="/cadastro" component={SignUpPage} />
    <Route path="/confirmar-email" component={SignUpValidationPage} />
    <Route path="/perfil" component={ShowProfilePage} />
    {/* <Route path="/artigos" component={ShowAriclesPage} /> */}
    {/* <Route path="/foruns" component={ShowForumsPage} /> */}
    <Route path="/editar-perfil" component={EditProfilePage} />
    <Route path="/enviar-foto" component={SendPhotoPage} />
    {/* <Route path="/configuracoes" component={SettingsPage} /> */}
    <Route path="/termos-de-uso" component={TermsOfUsePage} />
    {/* <Route path="/forum" component={ForumPage} /> */}
    <Route path="/aeronave" component={AircraftPage} />
    <Route path="/foto" component={PhotoPage} />
    {/* <Route path="/artigo" component={ArticlePage} /> */}
    {/* <Route path="/escrever-artigo" component={PostmakerPage} /> */}
    {/* <Route path="/editar-artigo" component={PostmakerPage} /> */}
    {/* <Route path="/tag" component={TagPage} /> */}
    <Route path="/busca" component={SearchPage} />
    <Route path="/pagina-nao-encontrada" component={Error404Page} />
    <Route path="/sobre" component={AboutPage} />
    <Route path="/esqueci-minha-senha" component={PasswordRecoveryPage} />
    <Route path="*" component={Error404Page} />
  </Switch>
);

export default Routes;
