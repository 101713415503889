import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Grid, LinearProgress, Alert, Box } from "@mui/material";
import TextField from "../../components/mui/TextField";
import Button from "../../components/mui/Button";
import { regEx } from "../../assets/scripts/RegEx";
import { getURL, getUrlParameter } from "../../assets/scripts/Main";

export default class Content extends Component {
  state = {
    email: "",
    password: "",
    confirmPassword: "",
    getCode: null,
    emailSent: null,
    loading: false,
    errorMsg: "",
    enableGetCodeButton: false,
    enableNewPasswordButton: false,
    showReloadButton: false,
  };

  handleEmailKeyPress = (event) => {
    this.setState({
      enableGetCodeButton: true,
      email: event.target.value,
    });
  };

  handlePasswordKeyPress = (event) => {
    this.setState({
      password: event.target.value,
    });
  };
  handleConfirmPasswordKeyPress = (event) => {
    this.setState({
      enableNewPasswordButton: true,
      confirmPassword: event.target.value,
    });
  };

  getRecoveryCode = () => {
    this.setState({
      loading: true,
      errorMsg: "",
    });
    if (this.state.email === "") {
      this.setState({ errorMsg: "Insira o email", loading: false });
    } else if (!regEx("email", this.state.email)) {
      this.setState({ errorMsg: "Formato de email inválido", loading: false });
    } else {
      axios
        .post(getURL("API") + "/PasswordRecovery/getCode", {
          email: this.state.email,
        })
        .then((response) => response.data)
        .then((data) => {
          this.setState({
            loading: false,
            emailSent: true,
          });
        })
        .catch((error) => {
          let msg =
            error.response.data.msg === "2"
              ? "Email não encontrado ou inativo"
              : "Erro ao gerar código de recuperação. Tente novamente mais tarde.";

          if (error.response.data.msg === "1") {
            msg = "Formato de email inválido";
          }
          this.setState({
            loading: false,
            errorMsg: msg,
          });
        });
    }
  };

  PasswordRecoveryValidate = () => {
    this.setState({
      loading: true,
      errorMsg: "",
    });
    if (
      this.state.password.length < 6 ||
      this.state.password.length > 150 ||
      !regEx("password", this.state.password)
    ) {
      this.setState({
        loading: false,
        errorMsg:
          "Sua senha deve ter entre 6 e 150 caracteres, contendo números, letras e caracteres especiais",
      });
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ loading: false, errorMsg: "As senhas não são iguais" });
    } else {
      axios
        .post(getURL("API") + "/PasswordRecovery/validate", {
          recoveryCode: getUrlParameter("code"),
          password: this.state.password,
          confirmPassword: this.state.confirmPassword,
        })
        .then((response) => response.data)
        .then((data) => {
          window.location.href = "../login?pwrstatus=s";
        })
        .catch((error) => {
          let msg = "Erro ao gerar nova senha. Tente novamente mais tarde.";

          if (error.response.data.msg === "1") {
            msg = "As senhas não são iguais";
          } else if (error.response.data.msg === "2" || error.response.data.msg === "3") {
            msg =
              "Sua senha deve ter entre 6 e 150 caracteres, contendo números, letras e caracteres especiais";
          } else if (error.response.data.msg === "4") {
            msg =
              "Código de recuperação inválido ou expirado. Clique no botão abaixo para gerar um novo";
          }
          this.setState({
            loading: false,
            errorMsg: msg,
            showReloadButton: error.response.data.msg === "4",
            enableNewPasswordButton: false,
          });
        });
    }
  };

  render() {
    return (
      <div id="passwordRecovery">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4>Recuperação de Senha</h4>

            {!(getUrlParameter("code") || this.state.emailSent) && (
              <p>Informe seu email, enviaremos um código para recuperação.</p>
            )}
            {getUrlParameter("code") && <p>Crie uma nova senha para acesso</p>}

            {this.state.emailSent && (
              <p>
                Enviamos um email de recuperação de senha para <strong>{this.state.email}</strong>.
                <br />
                <br />
                Caso não tenha recebido o email,{" "}
                <a onClick={this.getRecoveryCode} style={{ cursor: "pointer" }}>
                  clique aqui
                </a>{" "}
                para enviar novamente. Não se esqueça de verificar a caixa de spam
              </p>
            )}
          </Grid>
          {!(getUrlParameter("code") || this.state.emailSent) && (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={this.state.email || ""}
                  onChange={(e) => this.handleEmailKeyPress(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => this.getRecoveryCode()}
                  disabled={!this.state.enableGetCodeButton}
                  sx={{ width: "100%" }}
                >
                  Criar Nova Senha
                </Button>
              </Grid>
            </>
          )}

          {getUrlParameter("code") && (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Senha"
                  value={this.state.password || ""}
                  onChange={(e) => this.handlePasswordKeyPress(e)}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirmar Senha"
                  value={this.state.confirmPassword || ""}
                  onChange={(e) => this.handleConfirmPasswordKeyPress(e)}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => this.PasswordRecoveryValidate()}
                  disabled={!this.state.enableNewPasswordButton}
                  sx={{ width: "100%" }}
                >
                  Criar Nova Senha
                </Button>
              </Grid>
            </>
          )}
          <span className="errMsg" id="validateCodeErrMsg" ref="validateCodeErrMsg"></span>
          <Grid item xs={12}>
            {this.state.loading && <LinearProgress />}
            {this.state.errorMsg && (
              <Alert severity={"error"} variant="filled">
                {this.state.errorMsg}
              </Alert>
            )}
            {this.state.showReloadButton && (
              <Box mt={2}>
                <a href={"/esqueci-minha-senha"}>
                  <Button variant="contained" size="large" sx={{ width: "100%" }}>
                    Gerar novo código
                  </Button>
                </a>
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}
